import './App.css';
import React from "react";
import {withStyles} from '@mui/styles';
import ListItem from "@mui/material/ListItem";
import Button from "@mui/material/Button";
import Tooltip from '@mui/material/Tooltip';
import {isAfter, parseISO} from "date-fns";
import LockIcon from '@mui/icons-material/Lock';

function BoutonRedirect(props) {

    const redirect = (url) => {
        window.location.replace(url);
    }

    const setIsExpired = (schema) => {
        let result = false
        if (schema.dateExpiration && isAfter(new Date(), parseISO(schema.dateExpiration))) {
            schema.libelleEtat = 'Le schéma est expiré'
        }
        return result
    }

    const getUrlLocal = (url) => {
        let result = url;
        if (url) {
            let index = url.lastIndexOf('/')
            result = `http://localhost:8080/Jupiter${url.substring(index)}`
        }
        return result
    }

    // Composant personnalisé pour les tooltip.
    const HtmlTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: '#3b0110',
            color: 'rgba(252,247,247,0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(16),
            border: '1px solid #dadde9',
        },
    }))(Tooltip);

    setIsExpired(props.schema)
    const key = props.local ? `local_${props.schema.schema}` : `${props.schema.schema}`
    const url = props.local ? getUrlLocal(props.schema.url) : props.schema.url
    const libelle = props.local ? `${props.schema.libelle} (${props.schema.schema}) (localhost)` : `${props.schema.libelle} (${props.schema.schema})`
    const color = props.local ? `secondary` : `primary`

    return (<ListItem key={key}>
                <HtmlTooltip title={props.schema.libelleEtat} placement="right">
                    <span >
                        <Button
                            id={key}
                            color={color}
                            disabled={!!props.schema.libelleEtat}
                            variant="contained"
                            onClick={() => redirect(url)}>{libelle}{!!props.schema.libelleEtat ? <LockIcon /> : ''}
                        </Button>
                    </span>
                </HtmlTooltip>
            </ListItem>);
}

export default BoutonRedirect;
