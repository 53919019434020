import React from 'react';
import './index.css';
import App from './App';
import Keycloak from 'keycloak-js';
import { ReactKeycloakProvider } from '@react-keycloak/web'
import {createTheme, ThemeProvider} from '@mui/material/styles';
import { createRoot } from 'react-dom/client';
const container = document.getElementById('app');
const root = createRoot(container);

const keycloak = new Keycloak({
    "realm": "Jupiter",
    "url": process.env.REACT_APP_KEYCLOAK,
    "ssl-required": "external",
    "clientId": "pvo-front"
})

const palette = {
    primary: { main: '#546E7A' },
    secondary: { main: '#CFD8DC' }
};
const themeName = 'Jupiter';

const theme = createTheme({ palette, themeName });

//React Render
root.render(
        <ReactKeycloakProvider
            authClient={keycloak}
            initOptions={{
                onLoad: "login-required",
            }}>
            <ThemeProvider theme={theme}>
                <App />
            </ThemeProvider>
        </ReactKeycloakProvider>
);
