import './App.css';
import React from "react";
import Paper from '@mui/material/Paper';
import BoutonRedirect from './BoutonRedirect';
import {isAfter, parseISO} from 'date-fns'

function Accueil(props) {

    const redirect = (url) => {
        window.location.replace(url);
    }

    const isExpired = (schema) => {
        let result = false
        if (schema.dateExpiration) {
            result = isAfter(new Date(), parseISO(schema.dateExpiration))
        }
        return result
    }

    // Indique si présence d'un site principal valide pour une redirection immédiate.
    // Si on n'est pas en Prod, on fait comme si l'éventuel schéma principal valide n'existait pas
    // pour laisser le choix à l'utilisateur de se conecter en local.
    // On gère aussi le cas où l'utilisateur veut changer de schéma : forceSelection=true dans l'URL.
    const isFavoriValide = (account) => {
        const params = new URL(window.location.href).searchParams
        const forceSelection = params.get('forceSelection')
        let resultat = false;
        if (process.env.REACT_APP_LOCAL !== 'true' && account && account.schemaFavori && forceSelection !== 'true') {
            const favori = account.schemas.find(schema => schema.schema === account.schemaFavori)
            // Si le schéma favori n'est pas à l'état 0 ou bien est expiré, alors il n'est pas valide.
            if (favori && !favori.libelleEtat && !isExpired(favori)) {
                resultat = true
            }
        }
        return resultat
    }

    // Redirige l'utilisateur vers le schéma favori de l'utilisateur.
    const redirectionImmediateFavori = (account) => {
        const favori = account.schemas.find(schema => schema.schema === account.schemaFavori)
        redirect(favori.url)
    }

    // Les boutons de redirection.
    let boutons = props.account.schemas ?
        props.account.schemas.map(s=> <BoutonRedirect key={s.schema} schema={s} local={false} />)
        : <></>

    // Les boutons pour une connexion en local, uniquement si on est pas en Prod.
    let boutonJupiterLocal = process.env.REACT_APP_LOCAL === 'true' && props.account.schemas ?
        props.account.schemas.map(s=> <BoutonRedirect key={`local_${s.schema}`} schema={s} local={true} />)
        : <></>

    let listeBoutons = <ul>{boutons}{boutonJupiterLocal}</ul>

    // Si l'utilisateur a un schéma favori (principal) valide, alors on redirige directement.
    if (isFavoriValide(props.account)) {
        redirectionImmediateFavori(props.account)
    }

    return isFavoriValide(props.account) ?
        <></> :
        (<div className="App">
            <h1>Bonjour {props.account.libelle}</h1>
            <br/>
            <Paper elevation={3} style={{padding: 101}}>
                {listeBoutons}
            </Paper>
        </div>)
}

export default Accueil;
