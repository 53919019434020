import './App.css';
import React from "react";
import {useKeycloak} from '@react-keycloak/web'
import Accueil from './Accueil';

// Récupère les infos de l'utilisateur.
function App() {

    const getApplication = () => {
        let application = 'Jupiter'
        const params = new URL(window.location.href).searchParams
        const applicationParam = params.get('application')
        if (applicationParam && applicationParam !== 'null') {
            application = applicationParam
        }

        return application
    }

    const getRedirectUri = () => {
        let redirectUri = 'start.do'
        const params = new URL(window.location.href).searchParams
        const redirect = params.get('redirect')
        if (redirect && redirect !== 'null') {
            redirectUri = encodeURIComponent(redirect)
        }
        return redirectUri
    }

    // Hooks.
    const { keycloak, initialized } = useKeycloak();
    const [account, setAccount] = React.useState(null);

    React.useEffect(() => {
        async function fetchAuth(keycloak) {
            if (initialized) {
                const url = `${process.env.REACT_APP_HABILITATION}/auth/fromLogin?login=${keycloak.tokenParsed.preferred_username}&application=${getApplication()}&redirect=${getRedirectUri()}`
                const data = await fetch(url)
                return await data.json()
            }
        }
        async function fetchUserAccount() {
            const response = await fetchAuth(keycloak)
            setAccount(response)
        }
        fetchUserAccount()
    }, [keycloak, initialized]);

    return (initialized && account ? <Accueil account={account}/> : <></>);
}

export default App;
